import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checksumAddress } from 'viem';

const ArtisanDashboard = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [employers, setEmployers] = useState([]);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

 

  useEffect(() => {
    const fetchEmployers = async () => {
      try {

        const artisanQuery = query(collection(db, 'artisans'), where('artisanAddress', '==', userAddress));
        const querySnapshot = await getDocs(artisanQuery);
        const employerList = querySnapshot.docs.map(doc => doc.data().employerAddress);
        setEmployers(employerList);
      } catch (error) {
        console.error('Error fetching employers:', error);
        toast.error('Failed to fetch employers.');
      }
    };

    if (userAddress) {
      fetchEmployers();
    }
  }, [userAddress, provider]);

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-8">Artisan Dashboard</h2>
      {userAddress ? (
        <div>
          <h3 className="text-xl mb-4">Employers who have engaged your services:</h3>
          {employers.length > 0 ? (
            <ul className="list-disc pl-5">
              {employers.map((employer, index) => (
                <li key={index} className="mb-2">{employer}</li>
              ))}
            </ul>
          ) : (
            <p>No employers have engaged your services yet.</p>
          )}
        </div>
      ) : (
        <p>Please connect your wallet to view your dashboard.</p>
      )}
    </div>
  );
};

export default ArtisanDashboard;
