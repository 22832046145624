import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { baseSepolia } from 'viem/chains';
import ConnectWalletButton from './components/ConnectWalletButton';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { OnchainKitProvider } from '@coinbase/onchainkit';
import { WagmiProvider } from 'wagmi';
import { config } from './config.ts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ArtisansPage from './components/ArtisansPage';
import CreateArtisan from './components/CreateArtisan';
import ArtisanDashboard from './components/ArtisanDashboard';
import EmployerDashboard from './components/EmployerDashboard';

const queryClient = new QueryClient();

const DApp = ({ provider, setProvider }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [artistsDropdownOpen, setArtistsDropdownOpen] = useState(false);
  const [fansDropdownOpen, setFansDropdownOpen] = useState(false);

  const toggleDropdown = (dropdown, event) => {
    event.preventDefault();
    if (dropdown === 'artisan') {
      setArtistsDropdownOpen(!artistsDropdownOpen);
      setFansDropdownOpen(false);
    } else if (dropdown === 'employer') {
      setFansDropdownOpen(!fansDropdownOpen);
      setArtistsDropdownOpen(false);
    }
  };

  return (
    <div>
      <header className="bg-gray-800 py-4">
        <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold">WorkBase</Link>
          <nav className="hidden md:flex items-center">
            <Link to="/" className="ml-4">Home</Link>
            <div className="relative ml-4">
              <button
                className="flex items-center"
                onClick={(event) => toggleDropdown('artisan', event)}
              >
                Artisan
              </button>
              {artistsDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                  <Link to="/artisan/create" className="block px-4 py-2">Create Profile</Link>
                  <Link to="/artisan/dashboard" className="block px-4 py-2">Dashboard</Link>
                </div>
              )}
            </div>
            <div className="relative ml-4">
              <button
                className="flex items-center"
                onClick={(event) => toggleDropdown('employer', event)}
              >
                Employer
              </button>
              {fansDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                  <Link to="/employer/dashboard" className="block px-4 py-2">Dashboard</Link>
                </div>
              )}
            </div>
          </nav>
          <div className="flex items-center">
            <ConnectWalletButton setSigner={setProvider} />
            <button
              className="md:hidden text-white ml-4"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
        </div>
        {isOpen && (
          <nav className="md:hidden">
            <Link to="/" className="block px-4 py-2">Home</Link>
            <div className="block px-4 py-2" onClick={(event) => toggleDropdown('artisan', event)}>Artisan</div>
            {artistsDropdownOpen && (
              <div className="pl-4">
                <Link to="/artisan/create" className="block px-4 py-2">Create Profile</Link>
                <Link to="/artisan/dashboard" className="block px-4 py-2">Dashboard</Link>
              </div>
            )}
            <div className="block px-4 py-2" onClick={(event) => toggleDropdown('employer', event)}>Employer</div>
            {fansDropdownOpen && (
              <div className="pl-4">
                <Link to="/employer/dashboard" className="block px-4 py-2">Dashboard</Link>
              </div>
            )}
          </nav>
        )}
      </header>
      <div className="mt-8">
        <Routes>
          <Route path="/artisan/create" element={<CreateArtisan provider={provider} />} />
          <Route path="/artisan/dashboard" element={<ArtisanDashboard provider={provider} />} />
          <Route path="/employer/dashboard" element={<EmployerDashboard provider={provider} />} />
          <Route path="*" element={<ArtisansPage provider={provider} />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  const [provider, setProvider] = useState(null);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <OnchainKitProvider chain={baseSepolia}>
          <Router>
            <div className="bg-gray-900 text-white min-h-screen">
              <Routes>
                <Route
                  path="*"
                  element={<DApp provider={provider} setProvider={setProvider} />}
                />
              </Routes>
            </div>
          </Router>
        </OnchainKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
