import React, { useRef, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const CreateArtisan = ({ provider }) => {
  const artisanNameRef = useRef();
  const artisanProfessionRef = useRef();
  const ratePerHourRef = useRef();
  const artisanLocationRef = useRef();
  const artisanPictureRef = useRef();
  const artisanPhoneNumberRef = useRef();
  const artisanEmailRef = useRef();
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  const generateUniqueId = async () => {
    const querySnapshot = await getDocs(collection(db, 'artisans'));
    const artisanIds = querySnapshot.docs.map(doc => doc.data().id);
    let newId = 1;
    while (artisanIds.includes(newId)) {
      newId += 1;
    }
    return newId;
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress,
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const createArtisan = async (name, profession, rate, location, picture, phoneNumber, email, address) => {
    try {
      if (!name || !profession || !rate || !location || !picture || !phoneNumber || !email || !address) {
        toast.error('Please fill in all fields');
        return;
      }
      toast.info('Saving artisan profile...');

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const artisanId = await generateUniqueId();

      const artisanData = {
        id: artisanId,
        name,
        profession,
        rate,
        location,
        picture,
        phoneNumber,
        email,
        address,
      };

      await addDoc(collection(db, 'artisans'), artisanData);

      toast.success('Artisan profile created successfully!');
      window.location.href = '/dapp/artisan/dashboard';
    } catch (error) {
      console.error('Error creating artisan profile:', error);
      if (error.message === 'Ethereum provider not available') {
        toast.error('Ethereum provider not available. Please ensure your wallet is connected.');
      } else {
        toast.error('Failed to create artisan profile.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Create Artisan Profile</h2>
        <div className="flex flex-col gap-4">
          <input
            ref={artisanNameRef}
            type="text"
            placeholder="Artisan Name"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={artisanProfessionRef}
            type="text"
            placeholder="Profession"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={ratePerHourRef}
            type="number"
            placeholder="Rate per hour (in USD)"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={artisanLocationRef}
            type="text"
            placeholder="Location"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={artisanPictureRef}
            type="text"
            placeholder="Picture URL"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={artisanPhoneNumberRef}
            type="text"
            placeholder="Phone Number"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={artisanEmailRef}
            type="email"
            placeholder="Email"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <span className="text-sm text-gray-500">
            Phone number and email will only be available to employers that have paid for services.
          </span>
          <input
            type="text"
            placeholder="Wallet Address"
            value={userAddress}
            readOnly
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
        </div>
        <button
          className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
          onClick={() =>
            createArtisan(
              artisanNameRef.current.value,
              artisanProfessionRef.current.value,
              ratePerHourRef.current.value,
              artisanLocationRef.current.value,
              artisanPictureRef.current.value,
              artisanPhoneNumberRef.current.value,
              artisanEmailRef.current.value,
              userAddress
            )
          }
        >
          Create Artisan Profile
        </button>
      </div>
    </div>
  );
};

export default CreateArtisan;
