import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Name } from '@coinbase/onchainkit/identity';
import { SiweMessage } from 'siwe';
import { checksumAddress, createPublicClient, encodeFunctionData, decodeFunctionResult, http, getContract, parseUnits } from 'viem';
import { baseSepolia } from 'viem/chains';
import {
  createSmartAccountClient,
  ENTRYPOINT_ADDRESS_V06,
} from 'permissionless';
import { privateKeyToSimpleSmartAccount } from 'permissionless/accounts';
import { createPimlicoPaymasterClient } from 'permissionless/clients/pimlico';


// Replace with a working RPC URL
const rpcUrl = 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/MjedAxltHoKi2ys6lIIF0SArbTTM-TlM';
const usdcContractAddress = '0x036CbD53842c5426634e7929541eC2318f3dCF7e';


const ArtisansPage = ({ provider }) => {
  const [artisans, setArtisans] = useState([]);
  const [selectedHours, setSelectedHours] = useState({});
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const fetchArtisans = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'artisans'));
        const artisanList = querySnapshot.docs.map(doc => ({
          docId: doc.id, // Firestore document ID
          ...doc.data()
        }));
        setArtisans(artisanList);
      } catch (error) {
        console.error('Error fetching artisans:', error);
        toast.error('Failed to fetch artisans.');
      }
    };

    fetchArtisans();
  }, []);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0])); // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);


  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress, // Normalize the case of the address
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const handleHourChange = (id, hours) => {
    setSelectedHours(prev => ({ ...prev, [id]: hours }));
  };

  const employArtisan = async (artisan, hours) => {
    const totalAmount = artisan.rate * hours;

    try {
      // Handle payment logic here
      toast.success(`Employing ${artisan.name} for ${hours} hours at $${totalAmount}`);

      const artisanDocRef = doc(db, 'artisans', artisan.docId); // Use Firestore document ID
      const artisanDoc = await getDoc(artisanDocRef);

      if (!artisanDoc.exists()) {
        toast.error('Selected artisan does not exist');
        return;
      }

      const artisanData = artisanDoc.data();
      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }


      const publicClient = createPublicClient({
        transport: http(rpcUrl),
      });

      const simpleAccount = await privateKeyToSimpleSmartAccount(publicClient, {
        privateKey: '0x38fcd15834fd4db1c299d708d22e208f9c81c0c5a47920e7fa9d6b5954d3f750',
        factoryAddress: '0x9406Cc6185a346906296840746125a0E44976454',
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const pimlicoPaymaster = createPimlicoPaymasterClient({
        chain: baseSepolia,
        transport: http(rpcUrl),
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const smartAccountClient = createSmartAccountClient({
        account: simpleAccount,
        chain: baseSepolia,
        bundlerTransport: http(rpcUrl),
        middleware: {
          sponsorUserOperation: pimlicoPaymaster.sponsorUserOperation,
        },
      });

      const minTokenAbi = [
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'transfer',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
          name: 'balanceOf',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
      ];

      // Step 2: Check the number of decimals for the USDC token
      const usdcContract = getContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        publicClient,
      });
      const decimals = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'decimals',
      });

      // Step 3: Check the balance of the sender's address
      const balance = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'balanceOf',
        args: [userAddress],
      });

      
      const amount = parseUnits(totalAmount.toString(), decimals);
       // eslint-disable-next-line no-undef
      if (BigInt(balance) < amount) {
        toast.error('Insufficient USDC balance');
        return;
      }

      // Step 4: Create the transaction data for Circle USDC payment
      const callData = encodeFunctionData({
        abi: minTokenAbi,
        functionName: 'transfer',
        args: [artisan.address, amount.toString()],
      });

        // Step 5: Send the sponsored transaction
        try {
            const txHash = await smartAccountClient.sendTransaction({
              account: smartAccountClient.account,
              to: usdcContractAddress,
              data: callData,
              value: 0n,
            });
    
            console.log("✅ Transaction successfully sponsored!");
            toast.success(`✅ Transaction successfully. 🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txHash}`);
    
            const employmentData = [
                ...(artisanData.employer || []),
                {
                  employerAddress: userAddress,
                  hours: hours,
                  amountPaid: totalAmount,
                  timestamp: new Date().toISOString(),
                }
              ];
        
              await updateDoc(artisanDocRef, {
                employer: employmentData
              });
        
              toast.success('Employment recorded successfully.');
          } catch (sponsorError) {
            console.error('Error during sponsored transaction:', sponsorError);
            toast.error('Sponsored transaction failed. Trying fallback method.');
            try {
                const callDataFallback = encodeFunctionData({
                  abi: minTokenAbi,
                  functionName: 'transfer',
                  args: [artisan.address, amount.toString()],
                });
                const txFallback = await provider.request({
                  method: 'eth_sendTransaction',
                  params: [
                    {
                      from: userAddress,
                      to: usdcContractAddress,
                      data: callDataFallback,
                      value: '0x0', // No ETH is being sent, only token transfer
                    },
                  ],
                });
      
                console.log("✅ Transaction successfully paid using user's gas!");
                console.log(`🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txFallback}`);
      
                const employmentData = [
                    ...(artisanData.employer || []),
                    {
                      employerAddress: userAddress,
                      hours: hours,
                      amountPaid: totalAmount,
                      timestamp: new Date().toISOString(),
                    }
                  ];
            
                  await updateDoc(artisanDocRef, {
                    employer: employmentData
                  });
            
                  toast.success('Employment recorded successfully.');
              } catch (fallbackError) {
                console.error('Error during fallback transaction:', fallbackError);
                toast.error('Transaction failed.');
              }

          }
      

      // After successful payment, update the database
    
    } catch (error) {
      console.error('Error during employment:', error);
      toast.error('Failed to employ artisan.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-8">Artisans</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {artisans.map(artisan => (
          <div key={artisan.docId} className="bg-gray-800 p-6 rounded-lg shadow-md">
            <img src={artisan.picture} alt={artisan.name} className="w-full h-48 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">{artisan.name}</h3>
            <p className="mb-2"><strong>Profession:</strong> {artisan.profession}</p>
            <p className="mb-2"><strong>Rate:</strong> ${artisan.rate}/hr</p>
            <p className="mb-2"><strong>Location:</strong> {artisan.location}</p>
            <Name address={artisan.address} showAddress />
            <label htmlFor={`hours-${artisan.docId}`} className="block mb-2">Select Hours</label>
            <select
              id={`hours-${artisan.docId}`}
              className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
              value={selectedHours[artisan.docId] || ''}
              onChange={(e) => handleHourChange(artisan.docId, e.target.value)}
            >
              <option value="">Select hours</option>
              {[...Array(24).keys()].map(hour => (
                <option key={hour} value={hour + 1}>{hour + 1}</option>
              ))}
            </select>
            <button
              className="px-4 py-2 bg-indigo-600 rounded-lg text-white"
              onClick={() => employArtisan(artisan, selectedHours[artisan.docId])}
            >
              Employ
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtisansPage;
