import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { checksumAddress } from 'viem';

const EmployerDashboard = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [employedArtisans, setEmployedArtisans] = useState([]);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));
        console.log('User Address:', checksumAddress(addresses[0])); // Logging user address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    if (userAddress) {
      const fetchEmployedArtisans = async () => {
        try {
          const q = query(
            collection(db, 'artisans'),
            where('employer', 'array-contains', { employerAddress: userAddress })
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            toast.error('You are not an employer. Please employ an artisan first.');
            return;
          }
          console.log('Fetched Documents:', querySnapshot.docs); // Logging fetched documents
          const artisans = querySnapshot.docs.map(doc => doc.data());
          console.log('Employed Artisans:', artisans); // Logging employed artisans
          setEmployedArtisans(artisans);
        } catch (error) {
          console.error('Error fetching employed artisans:', error);
          toast.error('Failed to load employed artisans');
        }
      };

      fetchEmployedArtisans();
    }
  }, [userAddress]);

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Employer Dashboard</h2>
          <div>
            <h3 className="text-xl font-semibold mb-4">Employed Artisans</h3>
            {employedArtisans.length === 0 ? (
              <p>No artisans employed yet.</p>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {employedArtisans.map((artisan, index) => (
                  <div key={index} className="bg-gray-800 p-4 rounded-lg">
                    <img src={artisan.picture} alt={artisan.name} className="w-full h-40 object-cover mb-4 rounded-lg" />
                    <h4 className="text-lg font-bold mb-2">{artisan.name}</h4>
                    <p className="mb-1">Profession: {artisan.profession}</p>
                    <p className="mb-1">Rate per hour: ${artisan.rate}</p>
                    <p className="mb-1">Location: {artisan.location}</p>
                    <p className="mb-1">Phone: {artisan.phoneNumber}</p>
                    <p className="mb-1">Email: {artisan.email}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
      </div>
    </div>
  );
};

export default EmployerDashboard;
